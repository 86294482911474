import React from 'react';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default function Title({ post }) {
  return (
    (post.original_date && (
      <h1 class="mt-8 text-l font-light tracking-tight leading-none text-gray-800 text-center">
        <span class="text-transparent bg-clip-text font-light bg-gradient-to-r text-red-400">
          Memory from:&nbsp;&nbsp;
        </span>
        {dayjs(post.original_date, 'YYYY-MM-DD HH:mm:ss').format(
          'MMM DD YYYY, HH:mm:ss'
        )}
      </h1>
    )) || <p></p>
  );
}
