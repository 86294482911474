import React from 'react';
import Comment from './Comment';

export default function Comments({ post, onComment }) {
  const handleSubmit = event => {
    event.preventDefault();
    onComment(post.id, post.hash, event.target[0].value);
  };
  return (
    <div className="container">
      <section class="relative flex items-center justify-center antialiased mx-auto max-w-[80%] my-8">
        <div class="container px-0">
          <form onSubmit={handleSubmit}>
            <div class="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200">
              <div class="py-2 px-4 bg-white rounded-t-lg">
                <label for="comment" class="sr-only">
                  Your comment
                </label>
                <textarea
                  id="comment"
                  rows="4"
                  class="px-0 w-full text-sm text-gray-900 bg-white border-0 focus:outline-none focus:ring-red-400 focus:border-red-400"
                  placeholder="Write a comment..."
                  required
                ></textarea>
              </div>
              <div class="flex justify-between items-center py-2 px-3 border-t">
                <button
                  type="submit"
                  class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-100 rounded-lg"
                >
                  Post comment
                </button>
              </div>
            </div>
          </form>
          <p class="ml-auto text-xs text-gray-500">
            You can comment on a memory ONCE for each time it is displayed :)
          </p>
        </div>
      </section>
      <section class="relative flex items-center justify-center antialiased max-w-[80%] mx-auto my-8">
        <div class="container px-0">
          {post?.comments && post.comments.length > 0 ? (
            post.comments.map((comment, index) => {
              return <Comment key={index} comment={comment} />;
            })
          ) : (
            <p>{'No comments yet :('}</p>
          )}
        </div>
      </section>
    </div>
  );
}
