import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] =
  process.env.REACT_APP_CLIENT_URL;
axios.defaults.headers.common['Access-Control-Allow-Methods'] =
  'GET,PUT,POST,DELETE,PATCH,OPTIONS';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export default async function API(method, endpoint, body, config) {
  const res = await (method === 'get'
    ? axios.get(endpoint, config)
    : axios.post(endpoint, body, config));
  return res;
}
