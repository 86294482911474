import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default function Comment({ comment }) {
  return (
    <div class="flex-col w-full py-4 mx-auto mt-3 bg-white border-b-2 border-r-2 border-gray-200 rounded-lg">
      <div class="flex flex-row md-10">
        <div class="flex-col mt-1">
          <div class="flex items-center flex-1 px-4 font-bold leading-tight">
            {comment.username}
            <span class="ml-4 text-xs font-normal text-gray-500 float-right">
              {dayjs(comment.created_date, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )}
            </span>
          </div>
          <div class="flex-1 px-2 ml-2 text-sm font-medium leading-loose text-gray-600">
            {comment.comment}
          </div>
        </div>
      </div>
    </div>
  );
}
