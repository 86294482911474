import React, { useState, useEffect } from 'react';
import API from './API';
import { Navigate } from 'react-router-dom';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hasAccount, setHasAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const logoutSilent = async () => {
      setHasAccount(false);
      try {
        await API('post', '/logout');
      } catch (error) {
        console.error({ error });
        alert(error.response.data.error);
      }
    };

    logoutSilent();
  }, []);

  const login = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await API('post', '/login', {
        username,
        password
      });
      setHasAccount(true);
      setIsLoading(false);
    } catch (error) {
      console.error({ error });
      setHasAccount(false);
      setIsLoading(false);
      alert(error);
    }
  };

  return hasAccount ? (
    <Navigate to="/home" />
  ) : (
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-[#F0E5CF]">
      <div class="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 bg-[#F7F7F3]">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
            Sign in to your account
          </h1>
          <form class="space-y-4 md:space-y-6" action="#" onSubmit={login}>
            <div>
              <label
                for="username"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Your username
              </label>
              <input
                type="text"
                name="username"
                id="username"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 focus:outline-none focus:ring-red-400 focus:border-red-400"
                onChange={e => setUsername(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 focus:outline-none focus:ring-red-400 focus:border-red-400"
                onChange={e => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <button
              type="submit"
              class="w-full text-white bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={isLoading}
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
