import React from 'react';

export default function Post({ post }) {
  return (
    <img
      class="my-8 mx-auto lg:max-w-[40%] max-w-[80%] h-auto rounded-lg transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0"
      src={
        post.value != null
          ? post.value
          : 'https://via.placeholder.com/1200x1600'
      }
      alt=""
    />
  );
}
