import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Hearts } from 'react-loader-spinner';
import API from './API';

export default function Splash() {
  const [hasAuth, setHasAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authSilent = async () => {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        await API('post', '/auth');
        setHasAuth(true);
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 403) {
          setHasAuth(false);
        } else {
          console.error({ error });
          alert(error?.response?.data?.error);
        }
        setIsLoading(false);
      }
    };

    authSilent();
  }, []);

  return isLoading ? (
    <div class="flex h-screen">
      <div class="m-auto">
        <Hearts
          height="300"
          width="300"
          color="#F7F7F3"
          ariaLabel="hearts-loading"
          wrapperStyle={{}}
          wrapperClass="justify-center"
          visible={true}
        />
      </div>
    </div>
  ) : (
    <Navigate to={hasAuth ? '/home' : '/login'} />
  );
}
