import React, { useState, useEffect } from 'react';
import Title from './Title';
import Post from './Post';
import Comments from './Comments';
import API from './API';
import { Navigate } from 'react-router-dom';

export default function Home() {
  const [post, setPost] = useState('');
  const [hasAuth, setHasAuth] = useState(false);
  const [reload, setReload] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const {
          data: { data: post }
        } = await API('get', '/post');
        setPost(post);
        setHasAuth(true);
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 403) {
          setHasAuth(false);
        } else {
          console.error({ error });
          alert(error?.response?.data?.error);
        }
        setIsLoading(false);
      }
    };
    fetchPost();
  }, [reload]);

  const onComment = async (post_id, hash, comment) => {
    try {
      await API('post', `/post/${post_id}/comments`, {
        hash,
        comment
      });
      setHasAuth(true);
      setReload(true);
    } catch (error) {
      if (error?.response?.status === 403) {
        setHasAuth(false);
      } else {
        console.error({ error });
        alert(error?.response?.data?.error);
      }
    }
  };

  return isLoading ? (
    <p></p>
  ) : !hasAuth ? (
    <Navigate to="/login" />
  ) : (
    <div className="container">
      <>
        <Title post={post}></Title>
        <Post post={post}></Post>
        <Comments post={post} onComment={onComment}></Comments>
      </>
    </div>
  );
}
